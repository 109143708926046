.chart-container {
    position: relative;
    height: 40vh;
    width: 100%;
}

@media (max-width: 600px) {
    .chart-container {
        height: 60vh;
    }
}
